.article {
  margin: 55px 0 45px;
  &s-wrap & {
    margin: 55px 0;
    padding-bottom: 50px;
    border-bottom: 1px solid #e9e6e6;
    overflow: hidden;
  }
  > h1 {
    margin-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }
  &__title {
    margin-top: 0;
  }
  &__excerpt {
    p {
      margin-bottom: 0;
    }
  }
  &__meta {
    margin-top: 25px;
    font: 600 12px/1 @poppins;
    text-transform: uppercase;
    i {
      font-size: 12px;
      color: @green-light;
    }
  }
  &__thumbnail {
    display: block;
    text-align: center;
    margin-bottom: 25px;
  }
  @media(min-width: 431px) {
    &__thumbnail {
      float: right;
      margin-left: 15px;
      margin-bottom: 5px;
      max-width: 50%;
      border: 1px solid #dce4ed;
      border-radius: 30px;
      box-shadow: 0 0 15px #ededed;
      overflow: hidden;
    }
  }
  @media(min-width: @min-sm) {
    &__thumbnail {
      img {
        min-width: 380px;
      }
    }
  }
  @media(min-width: @min-md) {
    margin: 95px 0 65px;
    &s-wrap {
      margin: 100px 0;
    }
    &s-wrap & {
      margin: 55px 0 50px;
    }
    &__thumbnail {
      margin: 0;
      max-width: none;
    }
  }
}

/*PAGINATION*/
.blog-pagination {
  margin: 50px 0;
  text-align: center;
  .nav-links {
    font-size: 0;
  }
  .page-numbers {
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    min-width: 25px;
    margin: 3px;
    padding: 0 2px;
    font: 600 13px/27px @poppins;
    border-radius: 4px;
    background: @light-2;
    color: #666;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background 0.25s;
    &:hover,&:active, &:focus, &.current {
      background: @orange;
      color: #fff;
    }
    &.prev, &.next {
      white-space: nowrap;
      background: transparent;
      &:hover,&:active, &:focus, &.current {
        background:transparent;
        color: @orange;
      }
    }
    &.prev {
      margin-left: 0;
      i {
        margin-right: 6px;
      }
    }
    &.next {
      margin-right: 0;
      i {
        margin-left: 6px;
      }
    }
    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      color: @orange;
    }
  }
  .screen-reader-text {
    display: none;
  }
  .pagination {
    margin: 0;
  }
  @media(min-width: @min-xs) {
    .page-numbers {
      height: 40px;
      min-width: 40px;
      margin: 6px;
      padding: 0 5px;
      font-size: 16px;
      line-height: 40px;
      border-radius: 8px;
    }
  }
}