.content-accordion {
  background: @light-2;
  @orange: #ffbd00;
  .section-title {
    margin: 35px 0;
  }
  .article {
    margin: 0 auto 45px;
  }
  .footer-order-btn {
    margin-bottom: 15px;
  }
  &__header {
    margin: 0 0 30px 0;
    padding: 20px 68px 20px 20px;
    border-radius: 24px;
    position: relative;
    cursor: pointer;
    background: #fff;
    transition: 0.25s;
    user-select: none;
    &.open {
      background: @orange;
      h1, h2, h3, h4, h5, h6,
      .h1, .h2, .h3, .h4, .h5, .h6 {
        color: #fff
      }
    }
    &:after {
      content: '\f067';
      font: 23px/1 FontAwesome;
      position: absolute;
      top: 50%;
      margin-top: -22px;
      right: 18px;
      width: 44px;
      height: 44px;
      line-height: 44px;
      padding-top: 1px;
      text-align: center;
      border-radius: 100%;
      background: @orange;
      color: #fff;
    }
    &.open {
      &:after {
        content: '\f067';
        background: #fff;
        color: @orange;
      }
    }
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      &, & * {
        margin: 0;
        font: 400 16px/1.25 @poppins;
        color: #000;
      }
    }
  }
  &__body {
    padding: 0 20px 20px 20px;
    color: #000;
  }
  @media(min-width: @min-xs){
    &__header {
      border-radius: 50px;
    }
  }
  @media(min-width: @min-md){
    .section-title {
      margin: 75px 0;
    }
    .article {
      margin-bottom: 80px;
    }
    .footer-order-btn {
      margin-bottom: 40px;
    }
    &__header {
      margin: 0 0 20px 0;
      padding: 25px 80px 25px 30px;
      &:after {
        margin-top: -30px;
        right: 10px;
        width: 60px;
        height: 60px;
        line-height: 60px;
      }
    }
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      &, & * {
        font-size: 24px;
      }
    }
  }
}
