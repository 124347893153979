.svg(@file){
  background-image: url('img/icons/@{file}.svg');
}
.i-{
  &certificate {
    .svg('i-certificate');
  }
  &communication {
    .svg('i-communication');
  }
  &communication2 {
    .svg('i-communication2');
  }
  &download {
    .svg('i-download');
  }
  &eye {
    .svg('i-eye');
  }
  &guarantee {
    .svg('i-guarantee');
  }
  &human {
    .svg('i-human');
  }
  &list {
    .svg('i-list');
  }
  &list2 {
    .svg('i-list2');
  }
  &money {
    .svg('i-money');
  }
  &note {
    .svg('i-note');
  }

  &circle-{
    &cards {
      .svg('i-circle-cards');
    }
    &human {
      .svg('i-circle-human');
    }
    &pig {
      .svg('i-circle-pig');
    }
  }

  &pe-7s-{
    &config {
      .svg('i-pe-7s-config');
    }
    &help2 {
      .svg('i-pe-7s-help2');
    }
    &like2 {
      .svg('i-pe-7s-like2');
    }
    &medal {
      .svg('i-pe-7s-medal');
    }
    &note2{
      .svg('i-pe-7s-note2');
    }
    &piggy{
      .svg('i-pe-7s-piggy');
    }
      &wristwatch{
      .svg('i-pe-7s-wristwatch');
    }
  }
}