.free-features {
  background: @light-2;
  padding-bottom: 15px;
  .section-title {
    margin-top: 30px;
    margin-bottom: 5px;
  }
  .section-subtitle {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 1.25;
    text-align: center;
  }
  .colored-text {
    color: @orange;
  }
  &__item {
    max-width: 260px;
    width: 100%;
    margin: 0 auto 25px;
    padding: 0px 20px 40px;
    border-radius: 30px;
    background: #fff;
  }
  &__title {
    margin-bottom: 20px;
    font: 600 20px/1 @poppins;
    text-transform: uppercase;
    text-align: center;
    color: #000;
  }
  &__text {
    text-align: justify;
  }
  &__icon {
    display: block;
    margin: auto;
    width: 64px;
    height: 144px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }
  @media (min-width: @min-md) and (max-width: @max-lg) {
    .valign > div:nth-child(3) {
      clear: both;
    }
  }
  @media(min-width: @min-lg) {
    .section-title {
      margin-top: 75px;
      margin-bottom: 15px;
    }
    .section-subtitle {
      margin-bottom: 75px;
      font-size: 18px;
    }
    &__item {
      max-width: none;
      margin-bottom: 85px;
      padding: 0px 25px 25px;
    }
    &__icon {
      width: auto;
      height: 186px;
      background-size: auto;
    }
    &__title {
      line-height: 1.25;
    }
    .valign {
      &, & > div {
        display: flex;
      }
    }
  }
}