.top-block-inner {
	padding: 70px 0 45px;
	background: #222 url(img/bg-top-inner-page.jpg) no-repeat 50% 0;
	background-size: cover;
	text-align: center;
	&__content {
		text-transform: uppercase;
		color: #fff;
	}
	&__title {
		margin-top: 40px;
		margin-bottom: 15px;
		font: 400 48px/1 @poppins;
	}
	&__subtitle {
		margin-bottom: 40px;
		font: 400 24px/1 @poppins;
	}
	&__img {
		margin: 45px auto 40px;
		max-width: 450px;
	}
	@media (min-width: @min-sm) {
		padding: 125px 0 90px;
	}
}