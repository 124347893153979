.guarantees-list {
  &, .article & {
    margin: 0;
    padding: 0;
    &__item {
      list-style-type: none;
      margin-bottom: 40px;
      padding-bottom: 35px;
      border-bottom: 1px dashed #efefef;
      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
    .col-l {
      display: block;
      margin: 0 auto 30px;
      width: 250px;
      height: 220px;
      padding: 35px 20px 20px;
      border: 1px solid #dce4ed;
      border-radius: 30px;
      background: #fff;
      box-shadow: 0 0 15px #ededed;
    }
    .colored-text {
      color: @orange;
      display: block;
    }
    &__title {
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1.25;
      text-align: center;
    }
    &__icon {
      display: block;
      height: 86px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
    @media (min-width: @min-sm) {
      &__item {
        margin-bottom: 60px;
        white-space: nowrap;
        padding-bottom: 0;
        border-bottom: 0;
      }
      .col-l {
        display: inline-block;
        vertical-align: middle;
        margin-right: -250px;
        margin-bottom: 0;
      }
      &__title {
        white-space: normal;
      }
      &__text {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        margin-left: 250px + 75px;
      }
    }
  }
}