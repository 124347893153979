.page-404 {
  height: 100%;
  body {
    display: block;
    max-width: 1200px;
    margin: auto;
  }
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    width: 100%;
    min-height: 100%;
  }
  &__img-top {
    margin-bottom: 30px;
  }
  &__text {
    margin-bottom: 15px;
    font: 600 14px/1.33 @poppins;
    a {
      color: @green-light;
      text-decoration: underline;
      &:hover, &:focus, &:active {
        color: @orange;
      }
    }
  }
  &__img-bottom {
    width: 50%;
  }
  @media(min-width: @min-sm) {
    &__text {
      font-size: 16px;
      br {
        display: none;
      }
    }
    &__img-bottom {
      width: auto;
      align-self: flex-end;
    }
  }
}