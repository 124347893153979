html, body {
	font: 400 13px/1.33 Helvetica, "Helvetica Neue", Arial, sans-serif;
	@media(min-width: @min-sm) {
		font-size: 15px;
	}
}

body {
	margin: 0;
	background: #fff;
	color: #707070;
	display: flex;
	flex-direction: column;
	height: 100vh;
	min-height: 100vh;
	min-width: 300px;
}
.main {flex: 1 0 auto;}

@media (min-width: @min-lg) {
	.container {
		width: 1200px;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.openx-banner {
	text-align: center;
	margin: 20px 0;
}

/*COMMON: ALIGNMENTS*/
.alignleft,
.alignright,
.aligncenter {
	display: block;
	margin: 0.25em auto 1em auto;
	clear: both;
}

@media(min-width: @min-xs) {
	.alignleft {
		float: left;
		margin: 0.25em 2em 1em 0;
		clear: both;
		max-width: 50%;
	}
	.alignright {
		float: right;
		margin: 0.25em 0 1em 2em;
		clear: both;
		max-width: 50%;
	}
}
