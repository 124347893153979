#of-popup-container .of-special-offer-container input[type='email'].of-offer-email{
  width: 56%!important;
  font-family: Helvetica!important;
  font-size: 16px!important;
  font-weight: 400!important;
  line-height: 52.21px!important;
  letter-spacing: 0.16px!important;
  float: left!important;
  font-style: normal!important;
  margin-top: 345px!important;
  margin-left: 40px!important;
  height: 49px!important;
}
#of-popup-container .of-special-offer-container .of-offer-btn{
  padding: 9px 46px!important;
  cursor: pointer!important;
  margin: 345px 0 20px 6px!important;
  display: inline-block!important;
  height: 49px!important;
  float: left!important;
  box-shadow: 0 3px 7px rgba(230, 153, 139, 0.95)!important;
  border-radius: 3px;
  background-image: linear-gradient(to top, #f05c42 0%, #d02d10 100%)!important;
  font-family: Helvetica!important;
  font-size: 16px!important;
  font-weight: 700!important;
  text-transform: uppercase!important;
  letter-spacing: 0.16px!important;
}
#of-popup-container .of-thank-you-popup, #of-popup-container .of-special-offer-popup{
  background-position-x: 50%!important;
}
#of-popup-container .of-thank-you-popup .of-close-btn:before, #of-popup-container .of-special-offer-popup .of-close-btn:before{
  height: 0!important;
}
#of-popup-container .of-thank-you-popup .of-close-btn:after, #of-popup-container .of-special-offer-popup .of-close-btn:after{
  height: 0!important;
}
 #of-popup-container .of-special-offer-popup{
  height: 510px!important;
  max-height: 510px!important;
}
#of-popup-container .of-special-offer-container .of-offer-btn .of-offer-btn-text{
  font-family: Helvetica!important;
}
#of-popup-container .of-thank-you-popup .of-close-btn, #of-popup-container .of-special-offer-popup .of-close-btn{
  border: none!important;
  background: url("img/icon-close.png") no-repeat!important;
  top: 12px!important;
  right: 9px!important;
}
#of-popup-container .of-thank-you-popup .of-close-btn:hover, #of-popup-container .of-special-offer-popup .of-close-btn:hover{
  background: url("img/icon-close.png") no-repeat!important;
}
.of-thank-you-popup{
  height: 268px!important;
  max-height: 268px!important;
}