.customer-reviews {
  .section-title {
     margin: 30px 0;
   }
  &__item {
    max-width: 260px;
    margin: 0 auto 35px;
  }
  &__video {
    a {
      display: inline-block;
      position: relative;
      margin-bottom: 15px;
      &:after {
        content: '';
        position: absolute;
        width: 80px;
        height: 55px;
        top: 50%;
        left: 50%;
        margin-top: -27px;
        margin-left: -40px;
        background: url(img/youtube-btn.png) no-repeat 50% 50%;
        transition: 0.25s;
      }
      &:hover{
        &:after {
          transform: scale(1.1);
        }
      }
    }
  }
  &__author {
    font: 600 20px/1.25 @poppins;
    color: #000;
  }
  &__rating {
    margin-top: 3px;
    font-size: 0;
    text-align: right;
    white-space: nowrap;
    i {
      margin-left: 4px;
      font-size: 16px;
      color: @orange;
    }
  }
  @media(min-width: @min-md){
    padding-bottom: 40px;
    .section-title {
      margin: 75px 0;
    }
    &__item {
      max-width: 370px;
      margin: 0 auto 55px;
    }
    &__author {
      font-size: 24px;
    }
    &__rating {
      margin-top: 6px;
      i {
        font-size: 18px;
      }
    }
  }
}
