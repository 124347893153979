/*BUTTONS: BIG-BTN*/
.big-btn {
	display: inline-block;
	width: 100%;
	max-width: 260px;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 20px 40px;
	border: 0;
	border-radius: 30px;
	background: @red-light;
	font: 600 16px/1 @poppins;
	text-transform: uppercase;
	color: #fff;
	transition: 0.25s;
	&:hover, &:active, &:focus {
		background: darken(@red-light, 5%) !important;
		border-color: darken(@red-light, 5%) !important;
		color: #fff !important
	}
	@media(min-width: @min-xs) {
		max-width: 230px;
	}
}

/*BUTTONS: PAGE SCROLL-TOP
-------------------------------------------------*/
.scroll-button {
	@size: 40px;
	position: fixed;
	z-index: 1;
	left: 10px;
	bottom: 10px;
	width: @size;
	height: @size;
	text-align: center;
	color: darken(@orange, 5%);
	cursor: pointer;
	transition: 0.3s;
	opacity: 0.5;
	border: 1px solid darken(@orange, 5%);
	border-radius: 100%;
	&:hover, &:active, &:focus {
		opacity: 1;
		background: @orange;
		color: #fff;
	}
	&:before {
		content: "\f106";
		font: 24px/35px 'FontAwesome';
	}
}
