.testimonials {
  .section-title {
    margin: 35px 0;
  }
  &__item {
    max-width: 370px;
    margin: 0 auto 50px;
  }
  &__text-wrap {
    margin-bottom: 40px;
    padding: 40px 20px;
    border: 1px solid #dce4ed;
    border-radius: 7px;
    box-shadow: 0 0 15px #ededed;
    background: #fff;
    position: relative;
    &:before, &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      border-bottom: 0;
    }
    &:before {
      margin-top: 0px;
      margin-left: -21px;
      border-top: 21px solid #dce4ed;
      border-left: 21px solid transparent;
      border-right: 21px solid transparent;
    }
    &:after {
      margin-top: -1px;
      margin-left: -20px;
      border-top: 20px solid #fff;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
    }
  }
  &__name {
    margin-bottom: 15px;
    font-size: 20px;
    color: #000;
  }
  &__rating {
    margin-top: 3px;
    font-size: 0;
    text-align: right;
    white-space: nowrap;
    i {
      margin-left: 4px;
      font-size: 16px;
      color: @orange;
    }
  }
  &__text {
    font-size: 16px;
    text-align: justify;
  }
  &__avatar {
    display: block;
    vertical-align: middle;
    margin: auto;
    width: 140px;
    height: 140px;
    line-height: 128px;
    padding: 3px;
    border: 3px solid #e0f1fd;
    border-radius: 100%;
    background: #fff;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  @media(min-width: @min-sm) {
  }
  @media(min-width: @min-md) {
    padding-bottom: 40px;
    .section-title {
      margin: 75px 0;
    }
    &__text-wrap {
      min-height: 260px;
    }
  }
}
