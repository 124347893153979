.top-block {
	padding: 60px 0 80px;
	background: #222 url(img/bg-top-front-page.jpg) no-repeat 50% 0;
	background-size: cover;
	text-align: center;
	&__content {
		max-width: 260px;
		margin: auto;
		color: #fff;
		.colored-text {
			color: @orange;
		}
	}
	&__title {
		margin-top: 40px;
		margin-bottom: 10px;
		font: 600 32px/1.25 @poppins;
	}
	&__subtitle {
		margin-bottom: 15px;
		font: 400 16px/1.25 @poppins;
	}
	&__text {
		margin-bottom: 20px;
		font-size: 12px;
	}
	&__list {
		margin: 0 0 40px 0;
		padding: 0;
		list-style-type: none;
		font-size: 13px;
		li {
			margin-bottom: 5px;
		}
	}
	&__calculator {
		max-width: 260px;
		margin: 40px auto 0;
	}
	@media (min-width: @min-xs) {
		&__content {
			max-width: none;
		}
	}
	@media (min-width: @min-md) {
		padding-top: 150px;
		text-align: left;
		min-height: 720px;
		&__title {
			font-size: 48px;
			margin-bottom: 25px;
		}
		&__subtitle {
			font-size: 24px;
		}
		&__text {
			font-size: 16px;
			margin-bottom: 45px;
		}
		&__list {
			font-size: 20px;
			li {
				margin-bottom: 10px;
			}
		}
		&__calculator {
			float: right;
			max-width: 370px;
			margin-top: 0;
		}
	}
}

/*CRM simple price calculator*/
.top-block__calculator #of-widgets-simple-price-calc {
	padding: 22px 30px 20px;
	border-radius: 24px;
	background: @light-2;
	select {
		width: 100% !important;
		margin-bottom: 12px !important;
		height: 24px !important;
		padding: 0 6px !important;
		font: 400 12px/22px Helvetica, "Helvetica Neue", Arial, sans-serif !important;
	}
	.of-simple-title {
		margin-bottom: 20px;
		font: 600 12px/1 Helvetica, "Helvetica Neue", Arial, sans-serif;
		text-transform: none;
		color: #000;
	}
	.of-sprice-container {
		width: inherit !important;
		height: auto;
		margin-top: 5px;
		padding-bottom: 3px;
		font: 400 16px/1 @poppins;
		color: #000;
		border-color: #dedede;
	}
	.of-scontinue-btn {
		.big-btn !important;
		height: auto !important;
		width: 100% !important;
		min-width: inherit !important;
		padding-top: 12px !important;
		padding-bottom: 12px !important;
		font-size: 12px !important;
	}
	@media (min-width: @min-md) {
		padding: 40px;
		border-radius: 30px;
		select {
			height: 44px !important;
			margin-bottom: 20px !important;
			font-size: 15px !important;
			line-height: 42px !important;
			font-weight: 400 !important;
		}
		.of-simple-title {
			margin-bottom: 30px;
			font-size: 20px;
			font-weight: 400;
		}
		.of-sprice-container {
			margin-top: 0;
			margin-bottom: 10px;
			padding-bottom: 10px;
			font-size: 24px;
		}
		.of-scontinue-btn {
			max-width: none !important;
			padding-top: 20px !important;
			padding-bottom: 20px !important;
			font-size: 16px !important;
		}
	}
}

/*CRM simple price calculator
on page /home-2 (ux-experiment)*/
.page-home-2 .top-block__calculator #of-widgets-simple-price-calc {
	.of-sprice-container {
		display: none !important;
	}
	.of-scontinue-btn {
		margin-top: 10px;
	}
}

