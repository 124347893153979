.support {
  background: url(img/bg-support.jpg) no-repeat 50% 0;
  background-size: cover;
  padding-bottom: 20px;
  .section-title {
    margin: 35px 0;
  }
  &__item {
    max-width: 260px;
    margin: 0 auto 25px;
    text-align: center;
    cursor: pointer;
    &-wrap {
      padding: 30px 15px 25px;
      border-radius: 32px;
      background: #fff;
      position: relative;
      z-index: 1;
      transition: .25s;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  &__avatar {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 28px;
    width: 105px;
    height: 105px;
    line-height: 97px;
    padding: 2px;
    border: 2px solid #e0f1fd;
    border-radius: 100%;
    background: #fff;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &__name {
    font: 400 20px/1 @poppins;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 5px;
  }
  &__position {
    margin-bottom: 40px;
    font-size: 13px;
  }
  .btn-wrap {
    margin-bottom: 40px;
    text-align: center;
  }
  @media(min-width: @min-sm) {
    &__items {
      font-size: 0;
      margin: 0 -15px;
      text-align: center;
    }
    &__item {
      display: inline-block;
      vertical-align: top;
      width: 220px;
      margin: 0 15px 65px;
      position: relative;
      &-wrap {
        padding-top: 28px;
        padding-bottom: 24px;
        border-radius: 15px 15px 2.2% 2.2%;
      }
      &:before, &:after {
        content: '';
        position: absolute;
        bottom: -6px;
        width: 50%;
        height: 100%;
        background: #3f3f41;
      }
      &:before {
        left: 0;
        background: #f7b100;
        border-radius: 15px 0 0 15px;
      }
      &:after {
        right: 0;
        background: #3f3f41;
        border-radius: 0 15px 15px 0;
      }
      &:nth-child(10) {
        display: none;
      }
    }
    &__avatar {
      margin-bottom: 15px;
      width: 144px;
      height: 144px;
      line-height: 128px;
      padding: 4px;
      border-width: 4px;
    }
    &__name {
      font-size: 18px;
    }
    &__position {
      margin-bottom: 30px;
      font-size: 15px;
    }
  }
  @media(min-width: @min-md) {
    .section-title {
      margin: 75px 0;
    }
  }
  @media(min-width: @min-lg) {
    &__items {
      margin: 0 -8px;
    }
    &__item {
      margin-left: 8px;
      margin-right: 8px;
      width: 220px;
      &:nth-child(10) {
        display: inline-block;
      }
    }
  }
}
