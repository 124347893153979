.order-steps {
  background: @light-1;
  .section-title {
    margin: 40px 0 20px;
  }
  .col-r {
    max-width: 340px;
    margin: auto;
  }
  &__list {
    margin: 0;
    padding: 0;
    counter-reset: li;
  }
  &__item {
    list-style-type: none;
    margin-bottom: 35px;
    white-space: nowrap;
  }
  &__icon {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 55px;
    margin-right: 20px;
    position: relative;
    background-repeat: no-repeat;
    background-position: 9px 9px;
    background-size: 51px 46px;
    &:before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid @orange;
      border-radius: 100%;
      font: 600 10px/18px @poppins;
      text-align: center;
      color: #fff;
      background: #000;
      overflow: hidden;
    }
  }
  &__text {
    display: inline-block;
    vertical-align: middle;
    margin-top: 12px;
    font: 600 16px/1 @poppins;
    color: #000;
    text-transform: uppercase;
    white-space: normal;
  }
  .img-wrap {
    display: none;
  }
  .big-btn-wrap {
    margin-bottom: 45px;
    text-align: center;
  }
  @media(min-width: @min-sm){
    .col-r {
      margin: 0;
    }
    .img-wrap {
      display: block;
    }
  }
  @media(min-width: @min-md){
    .section-title {
      margin: 75px 0;
    }
    &__list {
      margin-top: 30px;
    }
    &__icon {
      width: 80px;
      height: 75px;
      background-size: auto;
      &:before {
        width: 24px;
        height: 24px;
        border-width: 2px;
        font-size: 12px;
        line-height: 21px;
        background-position: 11px 11px;
      }
    }
    &__text {
      font-size: 20px;
      line-height: 22px;
    }
  }
  @media(min-width: @min-lg){
    &__item {
      margin-bottom: 85px;
      &:last-child {
        margin-bottom: 60px;
      }
    }
  }
}