/*HEADER: LAYOUT*/

@gray: rgba(255,255,255, 0.2);

.header {
	flex-shrink: 0;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	width: 100%;
	border-color: #fff;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	background-color: #222;
	background-color: rgba(0, 0, 0, 0.8);
	.nav-col, .buttons-col {
		position: static;
	}
	.nav-col {
		padding-left: 0;
	}
	&-transparent {
		background-color: rgba(0, 0, 0, 0.1);
	}
	&.mmenu-open {background: rgba(0, 0, 0, 0.8);}
	@media(min-width: @min-sm) {
		.nav-col {
			padding-left: 15px;
		}
	}
	@media(min-width: @min-lg) {
		&__phone-col, .nav-col {
			padding-top: 28px;
		}
	}
}

/*HEADER: ANIMATION*/
.header {
	& {
		transition: background 0.25s;
	}
	&.animated {
		border-color: #000;
		background-color: #222;
		background-color: rgba(0, 0, 0, 0.8);
	}
	@media(min-width: @min-lg) {
		&, &__logo a, .header__phone-col, .nav-col {
			transition: 0.25s;
		}
		&.animated {
			.header__phone-col, .nav-col {
				padding-top: 12px;
			}
		}
		&.animated &__logo a {
			padding: 9px 0 17px;
		}
	}
}

/*HEADER: LOGO*/
.header__logo {
	max-width: 27px;
	img {
		width: 100%;
	}
	a {
		display: block;
		padding: 11px 0 11px 0;
	}
	&-big {
		display: none;
	}
	@media(min-width: @min-md) {
		max-width: 210px;
		a {
			padding: 12px 0 16px 0;
		}
		&-small {
			display: none;
		}
		&-big {
			display: block;
		}
	}
	@media(min-width: @min-lg){
		a {
			padding: 24px 0 32px 0;
		}
	}
}

/*HEADER: HEADER-PHONE*/
.header__phone {
	display: none;
	margin-top: 16px;
	border: 2px solid @gray;
	border-radius: 30px;
	float: right;
	white-space: nowrap;
	overflow: hidden;
	font-size: 0;
	img {
		max-width: 110px;
		margin: 0 5px 0 5px;
	}
	&-text {
		display: inline-block;
		vertical-align: middle;
		height: 30px;
		line-height: 30px;
		padding: 1px 9px 0 7px;
		font: 600 11px/30px @poppins;
		background: @gray;
		color: #fff;
		text-transform: uppercase;
		cursor: pointer;
		transition: 0.25s;
		&:hover {
			background: @orange;
		}
	}
	@media(min-width: @min-sm) {
		display: inline-block;
	}
	@media(min-width: @min-md){
		margin-top: 13px;
	}
	@media(min-width: @min-lg) {
		float: none;
		margin-top: 0;
	}
}
.list-country-header{
        margin-left: 10px!important;
}

/*HEADER: NAV*/
.nav {
	display: none;
	ul {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 0;
	}
	a {
		margin: 3px 5px;
		padding: 3px 0 2px;
		font-size: 12px;
		&:first-child {
			margin-left: 0;
		}
	}
	@media (min-width: @min-lg) {
		text-align: right;
		margin-left: 8%;
		margin-right: -25px;
		a {
			margin: 8px 5px;
		}
	}
}
.nav, .mobile-nav {
	ul {
		margin: 0;
		padding: 0;
	}
	li {
		list-style-type: none;
		display: inline-block;
	}
	a {
		display: inline-block;
		font-family: @poppins;
		text-transform: uppercase;
		text-decoration: none;
		color: #fff;
	}
	a:hover, a:active, a:focus,
	.current-menu-item a,
	.current_page_item a {color: @orange;}
}

/*HEADER: BUTTONS (order, login)*/
.header__buttons {
	float: right;
	margin: 20px 0 0 0;
	padding: 0;
	li {
		list-style-type: none;
		display: inline-block;
		vertical-align: middle;

	}
	a[href*="/order"],
	a[href*="/customers/login"],
	#of-widgets-login-tooltip a.of-widgets-open-tooltip {
		display: inline-block;
		vertical-align: middle;
		padding: 6px 10px 5px;
		font: 400 10px/1 @poppins;
		border: 1px solid #fff;
		border-radius: 30px;
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;
		transition: 0.25s;
		&:hover, &:active, &:focus {
			color: #fff;
			border-color: @orange;
			background: @orange;
			text-decoration: none;
		}
		@media (min-width: @min-xs) {
			padding: 9px 12px 8px;
			font-size: 12px;
			border-width: 2px;
		}
	}
	a[href*="/order"] {
		margin-right: 4px;
		border-color: #ecaa00;
		color: @orange;
	}
	@media (min-width: @min-xs) {
		margin: 15px 0 0 0;
	}
	@media (min-width: @min-md) {
		margin: -3px 0 10px 0;
	}
	@media (min-width: @min-lg) {
		margin: 1px 0 5px 0;
	}
	@media (min-width: @min-md) and (max-width: @max-lg) {
		.page-template-orders-php & {
			margin-top: 15px;
		}
	}  
}

/*fix NewCRM login-tooltip*/
.header {
	#of-widgets-login-tooltip {
		.of-widgets-open-tooltip {
			background-color: transparent;
			&:hover, &:active, &:focus {
				opacity: 1;
			}
			.of-widgets-login-popup {
				right: 0;
			}
		}
	}
}

/*HEADER: SLICKNAV MENU*/
.slicknav {
	&_menu {
		float: right;
		overflow: hidden;
	}
	&_open .slicknav_icon-bar{background: #fff;}
	&_btn {
		margin-top: 23px;
		margin-left: 10px;
		font: 700 16px/20px Arial, sans-serif;
		z-index: 10000000;
	}
	&_menutxt, &_icon {
		float: left;
	}
	&_menutxt {
		display: none;
	}
	&_icon-bar {
		display: block;
		font: 700 16px/20px Arial, sans-serif;
		width: 1.5em;
		height: 0.125em;
		margin: 3px;
		background: #eee;
	}
	&_nav {
		position: absolute;
		left: 0;
		top: 100%;
		z-index: 10;
		width: 100%;
		padding: 30px 0;
		clear: both;
		background: lighten(@dark-1, 10%);
		background: rgba(0, 0, 0, 0.75);
		border-bottom: 2px solid #000;
		text-transform: uppercase;
		text-align: center;
		overflow: auto;
		ul {margin: 0}
		li {display: block;}
		a {
			font-size: 16px;
			padding: 10px;
		}
	}
}

@media(min-width: @min-md) {
	.mobile-nav {display: none;}
	.nav {display: block;}
}
