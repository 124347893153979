.why-us {
  padding-bottom: 15px;
  background: @light-1;
  .section-title {
    margin: 40px 0;
  }
  .col-r {
    margin-left: 60px;
  }
  &__item {
    margin-bottom: 45px;
  }
  &__title {
    margin-bottom: 5px;
    font: 600 18px/1.25 @poppins;
    text-transform: uppercase;
    color: #000;
  }
  &__text {
    text-align: justify;
  }
  &__icon {
    float: left;
    width: 50px;
    height: 50px;
    margin-top: -5px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
  }
  @media(min-width: @min-sm) {
    .col-r {
      margin-left: 90px;
    }
    &__icon {
      width: 70px;
      height: 70px;
      margin-top: -15px;
    }
  }
  @media(min-width: @min-md) {
    padding-bottom: 45px;
    .section-title {
      margin: 75px 0;
    }
  }
}
