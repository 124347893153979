.top-features {
  background: @light-2;
  padding: 30px 0 0;
  .valign {
    display: flex;
    align-items: center;
  }
  &__item {
    margin: 15px auto 25px;
    max-width: 260px;
    white-space: nowrap;
  }
  &__icon {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    margin-right: -50px;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
  }
  .col-r {
    display: inline-block;
    vertical-align: middle;
    margin-left: 50px;
    padding-left: 10px;
    white-space: normal;
  }
  &__title {
    font: 600 17px/1 @poppins;
    margin-bottom: 5px;
    color: #000;
    text-transform: uppercase;
    //letter-spacing: -0.5px;
  }
  &__read-more {
    width: 90px;
    padding-top: 10px;
    margin: -45px auto 0;
    border-radius: 15px 15px 0 0;
    font: 600 8px/1 @poppins;
    text-align: center;
    text-transform: uppercase;
    color: #000;
    background: @light-2;
    cursor: pointer;
    &:after {
      content: '\f107';
      display: block;
      margin-top: 3px;
      font: 10px/1 'FontAwesome';
    }
  }
  @media(min-width: @min-xs) {
    &__item {
      max-width: 340px;
    }
  }
  @media(min-width: @min-md) {
    padding-bottom: 20px;
    &__item {
      margin: 35px auto;
      max-width: none;
    }
    &__icon {
      width: 70px;
      height: 70px;
      margin-right: -70px;
    }
    &__title {
      font-size: 18px;
    }
    .col-r {
      margin-left: 70px;
    }
    &__read-more {
      width: 170px;
      padding-top: 21px;
      margin-top: -60px;
      border-radius: 30px 30px 0 0;
      font-size: 12px;
      &:after {
        font-size: 16px;
      }
    }
  }
}