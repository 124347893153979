.bonus-system-list {
  &, .article & {
    margin: 0;
    padding: 0;
    &__item {
      list-style-type: none;
      margin-bottom: 40px;
      white-space: nowrap;
    }
    .col-r {
      display: inline-block;
      vertical-align: middle;
      margin-left: 75px;
    }
    &__title {
      margin-top: 0.25em;
      margin-bottom: .5em;
      font-size: 20px;
      text-transform: uppercase;
      white-space: normal;
    }
    &__text {
      display: inline-block;
      vertical-align: middle;
      white-space: normal;
    }
    &__icon {
      display: inline-block;
      vertical-align: top;
      height: 60px;
      width: 60px;
      margin-right: -60px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
    @media (min-width: @min-sm) {
      .col-r {
        margin-left: 150px;
      }
      &__icon {
        height: 120px;
        width: 120px;
        margin-right: -120px;
      }
    }
    @media (min-width: @min-md) {
      &__title {
        margin-top: 1em;
      }
    }
  }
}