@import "variables";

/*!
Theme Name: ux
*/

/*FONTs
-------------------------------------------------*/
/*POPPINS 400*/
@font-face {
	font-family: 'Poppins';
	src: url('./fonts/poppins/poppins-regular-webfont.woff2') format('woff2'),
	url('./fonts/poppins/poppins-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
/*POPPINS 600*/
@font-face {
	font-family: 'Poppins';
	src: url('./fonts/poppins/poppins-semibold-webfont.woff2') format('woff2'),
	url('./fonts/poppins/poppins-semibold-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

/*PLUGINS
-------------------------------------------------*/
@import (inline) "includes/libs/aos.min.css";
@import (inline) "includes/libs/lightgallery.min.css";

/*COMMON RULES
-------------------------------------------------*/
@import "includes/common";

/*TYPOGRAPHY
-------------------------------------------------*/
@import "includes/typography";

/*BUTTONS
-------------------------------------------------*/
@import "includes/buttons";

/*ICONS (svg)
-------------------------------------------------*/
@import "includes/icons";

/*HEADER
-------------------------------------------------*/
@import "includes/header";

/*FOOTER
-------------------------------------------------*/
@import "includes/footer";

/*TOP-BLOCK (front page)
-------------------------------------------------*/
@import "includes/inc-top-block";

/*TOP-BLOCK-INNER (inner pages)
-------------------------------------------------*/
@import "includes/inc-top-block-inner";

/*TOP-FEATURES
-------------------------------------------------*/
@import "includes/inc-top-features";

/*CUSTOMER-REVIEWS
-------------------------------------------------*/
@import "includes/inc-customer-reviews";

/*FREE-FEATURES
-------------------------------------------------*/
@import "includes/inc-free-features";

/*ORDER-STEPS
-------------------------------------------------*/
@import "includes/inc-order-steps";

/*SUPPORT
-------------------------------------------------*/
@import "includes/inc-support";

/*CONTENT-ACCORDION
-------------------------------------------------*/
@import "includes/inc-content-accordion";

/*WHY-US (page: about-us)
-------------------------------------------------*/
@import "includes/inc-why-us";

/*TESTIMONIALS (page: about-us)
-------------------------------------------------*/
@import "includes/inc-testimonials";

/*FRONT-PAGE
-------------------------------------------------*/
.front-page-content {
	padding: 25px 0;
	.container {
		padding-left: 30px;
		padding-right: 30px;
	}
	p {
		margin-bottom: 10px;
		line-height: 1.25;
		text-align: justify;
	}
	img {
		display: none;
	}
	@media(min-width: @min-sm){
		img {
			display: block;
		}
	}
	@media(min-width: @min-md){
		padding: 70px 0 95px;
		.container {
			padding-left: 40px;
			padding-right: 40px;
		}
		p {
			margin-bottom: 20px;
			line-height: 1.33;
		}
	}
}

.home .social-share {
	background: @light-2;
}

/*PAGE: ABOUT US
-------------------------------------------------*/
.about-page-content {
	.article {
		margin: 50px 0 60px 0;
		h1 {
			margin: 0.4em 0;
		}
		h2 {
			.h4
		}
		ul, ol {
			margin-top: 20px;
			margin-bottom: 20px;
		}
		li {
			margin-bottom: 5px;
		}
	}

	@media (min-width: @min-lg){
		.article {
			margin: 40px 15px 45px 0;
		}
		position: relative;
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
			padding-left: 15px;
			background: url(img/collage-about-us.jpg) no-repeat 15px 0;
			background-size: cover;
			box-sizing: content-box;
		}
	}
}

.footer-order-btn {
	margin-bottom: 50px;
	text-align: center;
	@media (min-width: @min-md) {
		margin-bottom: 70px;
	}
}

/*PAGE: PRICES
-------------------------------------------------*/
.prices-page-content {
	.article {
		margin: 50px 0 45px 0;
	}
	.section-title {
		margin-top: 1.8em;
	}
	@media (min-width: @min-md) {
		.article {
			margin: 95px 0 75px;
		}
	}
}
@import "includes/inc-bonus-system-list";

/*PAGE: ORDER / PREVIEW
-------------------------------------------------*/
.order-page-content {
	.article {
		margin: 100px 0 40px 0;
	}
	@media (min-width: @min-md) {
		.article {
			margin: 140px 0 60px;
		}
	}
}

/*NewCRM OF custom styles*/
#crm_of_container {
	.obx {
		border: 1px solid #C96 !important;
		background: #ffc766 !important
	}
	#button {
		background: #0C3 !important;
		background-image: linear-gradient(to bottom,#0C3,#030)!important;
		font-weight: 600 !important;
		cursor: pointer;
		&:hover, &:active, &:focus {
			box-shadow: inset 0 -2px #060!important;
			background: #030 !important;
			background-image: linear-gradient(to bottom,#030,#0C3)!important;
		}
	}
	.paypal-button.paypal-style-checkout.paypal-size-small .paypal-button-content,
	#previewform_layout .of-pay-card-btn {
		border: 1px solid #CC9966;
		line-height: 26px;
		height: 26px;
		vertical-align: middle;
		background: #ffc439;
		&:hover, &:active, &:focus {
			background: #ffb93c;
		}
	}
	#order_form .of-attach-button {
		background: linear-gradient(#e0ad55, #946d29) !important;
		border: 1px solid #bf8c34 !important;
	}

	#order_form .promocode-border {
		border-color: #000;
	}
	#order_form .label-total-price,
	#order_form .of-form-loader,
	#order_form .log-in a,
	#order_form .help-block a,
	#order_form a.blue-link {
		color: #000;
	}
	#of-container #order_form .label-promocode-save-sum {
		color: #000;
		font-weight: 700;
	}
}

/*PAGE: GUARANTEES
-------------------------------------------------*/
.guarantees-page-content {
	//padding-bottom: 15px;
	//.section-title {
	//	margin: 40px 0;
	//}
	.article {
		margin: 50px 0 45px 0;
	}
	@media (min-width: @min-md) {
		.article {
			margin: 95px 0 100px;
		}
		.section-title {
			//margin: 95px 0;
		}
		//padding-bottom: 40px;
	}
}
@import "includes/inc-guarantees-list";

/*BLOG
-------------------------------------------------*/
@import "includes/tpl-blog";

/*404
-------------------------------------------------*/
@import "includes/tpl-404";

/*CUSTOM SCROLLBAR
-------------------------------------------------*/
body::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
body::-webkit-scrollbar-track {
	background: #222;
}
body::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
	background: @orange;
	&:hover {
		background: darken(@orange, 5%);
	}
}

///*ZOPIM-CHAT CAPTCHA
//-------------------------------------------------*/
//@import "includes/inc-zopim-chat-captcha";


/*POPUP
-------------------------------------------------*/
@import "includes/popup";