.footer {
	flex-shrink: 0;
	text-align: center;
	&__top {
		padding: 20px 0;
		background: @dark-1;
	}
	&__middle {
		background: @dark-2;
		padding: 20px 0 0 0;
		.col-1, .col-2, .col-3 {
			margin: 30px 0;
		}
		.footer__terms  {
			margin-bottom: 35px;
			a{
				line-height: 30px;
			}
		}
	}
	&__bottom {
		padding: 20px 0 40px;
		background: @dark-1;
		&-text {
			font-size: 11px;
			line-height: 1.25;
			text-transform: uppercase;
			color: #fff;
		}
	}
	@media(min-width: @min-xs) {
		&__top {
			padding: 15px 0;
		}
	}
	@media(min-width: @min-sm) {
		&__middle {
			padding: 15px 0;
			.col-1 {text-align: left;}
			.col-3 {text-align: right;}
		}
		.valing {
			display: flex;
			align-items: center;
		}
	}
	@media(min-width: 1400px) {
		&__bottom {
			padding-bottom: 20px;
		}
	}

}

/*FOOTER: LOGO*/
.footer__logo a {
	display: inline-block;
	max-width: 165px;
	width: 100%;
	margin-bottom: 5px;
	img {
		width: 100%;
	}
	@media(min-width: @min-sm) {
		max-width: 210px;
	}
}

/*FOOTER: COPYRIGHT*/
.copyright {
	font-size: 9px;
	letter-spacing: -0.3px;
	color: #505050;
	@media(min-width: @min-sm) {
		font-size: 11px;
	}
}

/*FOOTER: PAYMENTS*/
.footer__payments {
	img {
		max-width: 104px;
	}
	@media(min-width: @min-sm) {
		img {
			max-width: none;
		}
	}
}
/*FOOTER: SOCIAL-SHARE*/
.footer .social-share {
	padding: 20px 0;
	&__item {
		display: inline-block;
		vertical-align: top;
	}
}

/*FOOTER: SOCIAL-LINKS*/
.footer .social-links {
	margin-bottom: 15px;
	a {
		display: inline-block;
		margin: 4px 2px;
		width: 36px;
		height: 36px;
		line-height: 36px;
		border-radius: 100%;
		font-size: 15px;
		text-align: center;
		background: #5a5a5a;
		color: @dark-2;
		transition: 0.25s;
		&:hover, &:active, &:focus {
			text-decoration: none;
			background: #fff;
		}
	}
	@media(min-width: @min-sm) {
		margin-bottom: 5px;
	}
}

/*FOOTER: FOOTER-TERMS*/
.footer__terms {
	ul {
		margin: 0;
		padding: 0;
		text-align: center;
	}
	li {
		list-style-type: none;
		display: inline-block;
		&:after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 12px;
			margin: 0 8px 0 13px;
			border-right: 1px solid #fff;
		}
		&:last-child {
			&:after {content: none;}
		}
	}
	a {
		margin: 3px 0;
		font-size: 11px;
		color: #fff;
	}
}

/*FOOTER: FOOTER-MENU*/
.footer__menu {
	ul {
		margin: 0;
		padding: 0;
		text-align: center;
	}
	li {
		list-style-type: none;
		display: block;
	}
	a {
		display: inline-block;
		margin: 10px 0;
		font-size: 13px;
		line-height: 1;
		color: #fff;
		&:hover, &:focus, &:active {text-shadow: 0 0 1px #fff;}
	}
	@media (min-width: @min-xs) {
		.footer__terms;
		li {
			&:after {
				margin: 0 15px 0 20px;
			}
		}
	}
}
/*FOOTER: FOOTER-LIST-COUNTRY*/
.footer__text_country{
    vertical-align: middle;
}
.footer__payments{
    margin-bottom: 10px;
}
.footer_country{
    color:#505050;
    font-size: 11px;

}
.list-country{
    cursor: pointer;
    margin: 0px!important;
    padding-left: 6px;
    opacity: 0.8;
    &:hover{opacity: 1;}
}
.flag-us{
    background-image: url(/wp-content/themes/ca-grademiners-ux/img/united-states--2-.png);
}
.flag-au{
    background-image: url(/wp-content/themes/ca-grademiners-ux/img/australia-icon.png);
}
.flag-uk{
    background-image: url(/wp-content/themes/ca-grademiners-ux/img/uk-icon.png);
}
.flag-de{
	background-image: url(/wp-content/themes/ca-grademiners-ux/img/de-icon.png);
}
.flag-img{
        background-size: contain;
    background-repeat: no-repeat;
        width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    text-indent: 100%;
		white-space: nowrap;
		overflow: hidden;
    opacity: 0.8;
    &:hover{opacity: 1;}
    
}

/*FOOTER: TRUST-SECURITY*/
.footer__trust-security {
	margin-bottom: 30px;
	margin-top: 10px;
	font-size: 0;
	white-space: nowrap;
	a{
		margin-right: 35px;
	}
	&-text {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
		text-align: right;
		font: 600 14px/1 @poppins;
		color: #fff;
		@media(max-width: @min-sm) {
			margin-left: -32px;
		}
		.big-text {
			font-size: 24px;
		}
	}
	&:after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 47px;
		height: 62px;
		background: url(img/icons/i-trust-security.svg) no-repeat;
	}
	@media(min-width: @min-sm) {
		margin-bottom: 15px;
	}
}
#disclaimer{
	background-image: url('img/ewriterspro-adress.png')!important;
}