.article {
	p, li, blockquote {
		margin-bottom: 20px;
		text-align: justify;
	}
}

/*TYPOGRAPHY: LINKs*/
a {
	color: @orange;
	&:hover, &:active, &:focus {
		color: @orange;
		text-decoration: underline;
	}
}

/*TYPOGRAPHY: BLOCKQUOTE*/
.article blockquote {border-color: @green-light;}

/*TYPOGRAPHY: HEADERS*/
.headers(@font-size-mobile, @font-size-desktop){
	margin: 1em 0;
	font: 600 @font-size-mobile/1.25 @poppins;
	color: #000;
	a {
		color: #000;
		&:hover, &:active, &:focus {
			color: @orange;
			text-decoration: none;
		}
	}
	@media (min-width: @min-sm) {
		font-size: @font-size-desktop;
	}
}

h1, .h1 {
	.headers(28px, 40px);
}
h2, .h2 {
	.headers(20px, 30px);
}
h3, .h3 {
	.headers(18px, 24px);
}
h4, .h4 {
	.headers(16px, 18px);
}
h5, .h5 {
	.headers(14px, 16px);
}
h6, .h6 {
	.headers(12px, 14px);
}

/*TYPOGRAPHY: SECTION TITLE*/
.section-title {
	.h1;
	text-align: center;
	br {
		display: none;
	}
	@media (min-width: @min-md) {
		br {
			display: block;
		}
	}
}

/*TYPOGRAPHY: LIST ITEMS*/
.article, .content {
	ol, ul {
		margin: 40px 0 40px 40px;
		padding: 0;
	}
	ul li {
		list-style-type: none !important; // fix for terms page
		> p:first-child {display: inline;} // fix for terms page
		&:before {
			content: '\f00c';
			font: 18px/1 FontAwesome;
			width: 18px;
			height: 18px;
			display: inline-block;
			vertical-align: top;
			margin-right: 5px;
			margin-left: -23.5px;
			color: @green-light;
		}
	}
}